export { default as Link } from 'next/link';
export { default as Router, useRouter } from 'next/router';
export { DefaultSeo, NextSeo as SEO } from 'next-seo';
export { AnimatePresence, motion, useCycle, useAnimation } from 'framer-motion';
export { default as NProgress } from 'nprogress';
export { ErrorBoundary } from 'react-error-boundary';
export { default as Tooltip } from 'react-tooltip';
export { Waiter } from 'react-wait';
export { PersistGate } from 'redux-persist/integration/react';
export { UserProvider } from '@auth0/nextjs-auth0';
export { Container, Row, Col, Hidden } from 'react-grid-system';
export { Toaster } from 'react-hot-toast';

// Custom
export { default as Layout } from './Core/Layout';
export { default as ErrorFallback } from './Core/ErrorFallback';
export { default as HighlightWord } from './Core/HighlightWord';
export { default as AnimatedButton } from './Core/AnimatedButton';

// Old landing page
export { default as Button } from './Core/Button';
export { default as ButtonGroup } from './Core/ButtonGroup';
