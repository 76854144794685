export { default as VuesaxBulkElement } from '@public/icons/vuesax/vuesax-bulk-element-1.svg';
export { default as VuesaxBulkMagicStar } from '@public/icons/vuesax/vuesax-bulk-magic-star.svg';
export { default as VuesaxBulkNotification } from '@public/icons/vuesax/vuesax-bulk-notification.svg';
export { default as VuesaxBulkNotificationEmpty } from '@public/icons/vuesax/vuesax-bulk-notification-empty.svg';
export { default as VuesaxBulkFolderTwo } from '@public/icons/vuesax/vuesax-bulk-folder-2.svg';
export { default as VuesaxOutlineClock } from '@public/icons/vuesax/vuesax-outline-clock.svg';
export { default as VuesaxOutlineClipboardTick } from '@public/icons/vuesax/vuesax-outline-clipboard-tick.svg';
export { default as VuesaxOutlineImage } from '@public/icons/vuesax/vuesax-outline-image.svg';
export { default as VuesaxOutlineUserOctagon } from '@public/icons/vuesax/vuesax-outline-user-octagon.svg';
export { default as VuesaxOutlineBrush } from '@public/icons/vuesax/vuesax-outline-brush.svg';
export { default as VuesaxOutlineTrendUp } from '@public/icons/vuesax/vuesax-outline-trend-up.svg';
export { default as VuesaxOutlineShoppingBag } from '@public/icons/vuesax/vuesax-outline-shopping-bag.svg';
export { default as VuesaxOutlineChartSuccess } from '@public/icons/vuesax/vuesax-outline-chart-success.svg';
export { default as VuesaxOutlineDocumentLike } from '@public/icons/vuesax/vuesax-outline-document-like.svg';
export { default as VuesaxOutlineUserTick } from '@public/icons/vuesax/vuesax-outline-user-tick.svg';
export { default as VuesaxOutlineCrown } from '@public/icons/vuesax/vuesax-outline-crown.svg';
export { default as VuesaxOutlineMessageNotif } from '@public/icons/vuesax/vuesax-outline-message-notif.svg';
export { default as VuesaxOutlineMessage } from '@public/icons/vuesax/vuesax-outline-message.svg';
export { default as VuesaxOutlineSearchNormal } from '@public/icons/vuesax/vuesax-outline-search-normal.svg';
export { default as VuesaxOutlineArrowDown } from '@public/icons/vuesax/vuesax-outline-arrow-down.svg';
export { default as VuesaxOutlineChevronLeft } from '@public/icons/vuesax/vuesax-outline-chevron-left.svg';
export { default as VuesaxOutlineChevronRight } from '@public/icons/vuesax/vuesax-outline-chevron-right.svg';
export { default as VuesaxOutlineClose } from '@public/icons/vuesax/vuesax-outline-close.svg';
export { default as VuesaxOutlineCloseNoColor } from '@public/icons/vuesax/vuesax-outline-close-nocolor.svg';
export { default as VuesaxOutlineDocumentCopy } from '@public/icons/vuesax/vuesax-outline-document-copy.svg';
export { default as VuesaxOutlineCheck } from '@public/icons/vuesax/vuesax-outline-check.svg';
export { default as VuesaxOutlineWalletMoney } from '@public/icons/vuesax/vuesax-outline-wallet-money.svg';
export { default as VuesaxOutlineMoneySend } from '@public/icons/vuesax/vuesax-outline-money-send.svg';
export { default as VuesaxOutlineReceiptItem } from '@public/icons/vuesax/vuesax-outline-receipt-item.svg';
export { default as VuesaxOutlineBriefcase } from '@public/icons/vuesax/vuesax-outline-briefcase.svg';
export { default as VuesaxOutlineSetting } from '@public/icons/vuesax/vuesax-outline-setting.svg';
export { default as VuesaxOutlineSurge } from '@public/icons/vuesax/vuesax-outline-surge.svg';
export { default as VuesaxOutlineMessageAdd } from '@public/icons/vuesax/vuesax-outline-message-add.svg';
export { default as VuesaxOutlineHeart } from '@public/icons/vuesax/vuesax-outline-heart.svg';
export { default as VuesaxOutlineHeartFull } from '@public/icons/vuesax/vuesax-outline-heart-full.svg';
export { default as Hexagon } from '@public/icons/hexagon.svg';
export { default as HexagonBadge } from '@public/icons/hexagon-badge.svg';
export { default as HexagonPaperSheet } from '@public/icons/hexagon-paper-sheet.svg';
export { default as TabletChecked } from '@public/icons/tablet-checked.svg';
export { default as SocialFacebook } from '@public/icons/social/social-facebook.svg';
export { default as SocialFacebookSolid } from '@public/icons/social/social-facebook-solid.svg';
export { default as SocialGooglePlus } from '@public/icons/social/social-google-plus.svg';
export { default as SocialICloud } from '@public/icons/social/social-icloud.svg';
export { default as SocialInstagram } from '@public/icons/social/social-instagram.svg';
export { default as SocialInstagramSolid } from '@public/icons/social/social-instagram-solid.svg';
export { default as SocialTiktok } from '@public/icons/social/social-tiktok.svg';
export { default as SocialTiktokSolid } from '@public/icons/social/social-tiktok-solid.svg';
export { default as SocialTwitter } from '@public/icons/social/social-twitter.svg';
export { default as SocialTwitterSolid } from '@public/icons/social/social-twitter-solid.svg';
export { default as SocialDiscord } from '@public/icons/social/social-discord.svg';
export { default as SocialYoutube } from '@public/icons/social/social-youtube.svg';
export { default as BaselinePages } from '@public/icons/baseline/baseline-pages-24px.svg';
export { default as BaselineClose } from '@public/icons/baseline/baseline-close-24px.svg';
export { default as BaselineMousewheel } from '@public/icons/baseline/baseline-mousewheel.svg';
export { default as BaselineArrowLeft } from '@public/icons/baseline/baseline-arrow-left-24px.svg';
export { default as BaselineArrowRight } from '@public/icons/baseline/baseline-arrow-right-24px.svg';
export { default as BaselineDebit } from '@public/icons/baseline/baseline-debit-24px.svg';
export { default as BaselineSearch } from '@public/icons/baseline/baseline-search-24px.svg';
export { default as InstagramOutline } from '@public/icons/instagram-outline.svg';
export { default as Followership } from '@public/icons/Followership-colorless.svg';
export { default as IconTrash } from '@public/icons/icon-trash.svg';
export { default as IconDownload } from '@public/icons/download-icon.svg';
