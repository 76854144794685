import { createContext } from 'react';

export type CampaignGlobalMetaContext = {
  meta: {
    activeOrders: number;
    totalCampaigns: number;
    infCount: number;
    totalBudget: number;
  };
  isLoading: boolean;
  error: any;
  mutate: Function;
};

const noop = () => {};

const CampaignGlobalMetaContext = createContext<CampaignGlobalMetaContext>({
  meta: {
    activeOrders: 0,
    totalCampaigns: 0,
    infCount: 0,
    totalBudget: 0,
  },
  isLoading: false,
  error: null,
  mutate: noop,
});

export default CampaignGlobalMetaContext;
