import useSWR, { SWRConfiguration } from 'swr';

export const fetcher = async (...args: Parameters<typeof fetch>) => {
  return fetch(...args).then(response => response.json());
};

const getAbsoluteURL = (path: string | null = '') => {
  if (path === null) {
    return null;
  }
  let URL =
    process.env.NEXT_PUBLIC_VERCEL_URL ||
    `http${process.env.NEXT_PUBLIC_USE_LOCALHOST_HTTPS ? 's' : ''}://localhost:3001`;
  if (URL.endsWith('/')) URL = URL.slice(0, -1);
  URL = `${URL}${path}`;
  if (URL.startsWith('http')) return URL;
  return URL.includes('localhost')
    ? `http${process.env.NEXT_PUBLIC_USE_LOCALHOST_HTTPS ? 's' : ''}://${URL}`
    : `https://${URL}`;
};

export default function useAPI<Response = any>(
  URL: string | null,
  options?: SWRConfiguration,
): {
  data: Response;
  error: any;
  isLoading: boolean;
  mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
} {
  const { data, error, mutate } = useSWR(getAbsoluteURL(URL), fetcher, options);
  return { data, error, isLoading: !error && !data, mutate };
}
