import { EmployeeGroup, Industry, Revenue, SellStatus } from '@lib/db/models/dictionaryBrand';
import find from 'lodash.find';

export const UserRole = {
  ADMIN: 'admin',
  INFLUENCER: 'user',
  BRAND: 'brand-manager',
};

export enum SocialNetwork {
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  TIK_TOK = 'tik_tok',
  FACEBOOK = 'facebook',
  DISCORD = 'discord',
}

export enum Gender {
  NOT_SET = 0,
  MALE = 1,
  FEMALE = 2,
  TRANSGENDER = 3,
  NON_BINARY_CONFIRMING = 4,
}

export enum Age {
  AGE_0_19 = 0,
  AGE_20_29 = 1,
  AGE_30_39 = 2,
  AGE_40_49 = 3,
  AGE_50_59 = 4,
  AGE_60_69 = 5,
  AGE_70_PLUS = 6,
  NOT_SELECTED = 10,
}

interface BrandInfoField {
  name: string;
  label: string;
  options: Dropdown.DropdownOption[];
  initialValue?: any;
}
export const brandInfoFields: BrandInfoField[] = [
  {
    name: 'brandInfoIndustry',
    label: 'Industry',
    options: [
      { value: Industry.BEAUTY, label: 'Beauty' },
      { value: Industry.CLOTHING, label: 'Clothing' },
      { value: Industry.ELECTRONICS, label: 'Electronics' },
      { value: Industry.FURNITURE, label: 'Furniture' },
      { value: Industry.JEWELRY, label: 'Jewelry' },
      { value: Industry.PAINTING, label: 'Painting' },
      { value: Industry.PHOTOGRAPHY, label: 'Photography' },
      { value: Industry.RESTAURANT, label: 'Restaurant' },
      { value: Industry.GROCERIES, label: 'Groceries' },
      { value: Industry.FOOD_AND_DRINKS, label: 'Food and drinks' },
      { value: Industry.SPORTS, label: 'Sports' },
      { value: Industry.OTHER, label: 'Other' },
    ],
  },
  {
    name: 'brandInfoRevenue',
    label: 'Current revenue',
    options: [
      { value: Revenue.STARTER, label: '$0 (I am getting started)' },
      { value: Revenue.YEAR_0_5K, label: 'Up to $5,000 USD a year' },
      { value: Revenue.YEAR_5_50K, label: '$5,000 USD to $50,000 a year' },
      { value: Revenue.YEAR_50_250K, label: '$50,000 USD to $250,000 a year' },
      { value: Revenue.YEAR_250_1000K, label: '$250,000 USD to $1,000,000 a year' },
      { value: Revenue.YEAR_1000K_PLUS, label: '$1,000,000+ a year' },
    ],
  },
  {
    name: 'brandInfoSellStatus',
    label: 'Are you already selling?',
    options: [
      { value: SellStatus.TESTING, label: 'I am just playing around' },
      { value: SellStatus.NOT_SELLING_YET, label: 'I am not selling products yet' },
      { value: SellStatus.SELLING_OFFLINE, label: 'I am selling, just not online' },
      { value: SellStatus.SELLING, label: 'I am selling' },
    ],
  },
  // {
  //   name: 'brandInfoDemographics',
  //   label: 'Personal Demographics Owner',
  //   options: [{ value: 'Other', label: 'Other' }],
  // },
  {
    name: 'brandInfoEmployees',
    label: 'Number of employees',
    options: [
      { value: EmployeeGroup.EMPLOYEE_0_5, label: 'less than 5' },
      { value: EmployeeGroup.EMPLOYEE_5_10, label: '5-10' },
      { value: EmployeeGroup.EMPLOYEE_10_50, label: '10-50' },
      { value: EmployeeGroup.EMPLOYEE_50_100, label: '50-100' },
      { value: EmployeeGroup.EMPLOYEE_100_PLUS, label: '100+' },
    ],
  },
];

export const findBrandInfoOption = (name: any, value: any) =>
  find(find(brandInfoFields, { name })?.options, { value });

export const genderOptions: { value: Gender; label: string }[] = [
  { value: Gender.MALE, label: 'Man' },
  { value: Gender.FEMALE, label: 'Woman' },
  { value: Gender.TRANSGENDER, label: 'Transgender' },
  { value: Gender.NON_BINARY_CONFIRMING, label: 'Non-binary/non-conforming' },
  { value: Gender.NOT_SET, label: 'Prefer not to respond' },
];

export const ageOptions: { value: Age; label: string }[] = [
  { value: Age.AGE_0_19, label: 'Under 20' },
  { value: Age.AGE_20_29, label: '20-29' },
  { value: Age.AGE_30_39, label: '30-39' },
  { value: Age.AGE_40_49, label: '40-49' },
  { value: Age.AGE_50_59, label: '50-59' },
  { value: Age.AGE_60_69, label: '60-69' },
  { value: Age.AGE_70_PLUS, label: '70+' },
];

type SocialNetworkType = keyof typeof SocialNetwork;

export interface IGMeta {
  username?: string;
  longToken?: string;
  pageId?: string;
  id?: string;
  name?: string;
  biography?: string;
  profilePictureUrl?: string;
  website?: string;
  followersCount?: number;
  followsCount?: number;
  mediaCount?: number;
  updatedAt?: number;
  lastItsUpdated?: number;
  lastMentionUpdated?: number;
}
