import React from 'react';
import Logo from '@components/Core/Logo';
import Link from 'next/link';
import classnames from 'classnames';
import { BaselineClose, BaselinePages, SocialFacebookSolid } from '@components/Core/Icons';
import LandingHeaderAuthButtons from '@components/Index/LandingHeader/LandingHeaderAuthButtons';
import { InstagramOutline } from '@components/Core/Icons';

type Props = {
  className?: string;
  navClassName?: string;
  menuShown: boolean;
  onMenuShow: () => any;
  onMenuHide: () => any;
  showAuthButtons?: boolean;
  showLinks?: boolean;
  authButtons?: () => React.ReactNode | React.ReactNode[];
};

type Link = {
  label: string;
  href: string;
};

export const links: Link[] = [
  { label: 'Ambassadors', href: '/ambassadors-info' },
  { label: 'Brands', href: '/brands-info' },
  { label: 'Campaigns', href: '/ambassadr-campaigns' },
  // { label: 'Exec summary', href: '/exec-summary' },
  { label: 'About', href: '/about' },
];

export const socialAccounts = [
  {
    label: 'Instagram',
    icon: <InstagramOutline className="text-white transform scale-75" />,
    color: 'gradient-instagram',
    href: 'https://www.instagram.com/ambassadr/',
  },
  {
    label: 'Facebook',
    icon: <SocialFacebookSolid className="text-white w-4 h-4" />,
    color: 'facebook',
    href: 'https://www.facebook.com/ambassadr/',
  },
];

const LandingHeader: React.FC<Props> = ({
  className,
  navClassName,
  menuShown,
  onMenuShow,
  onMenuHide,
  showAuthButtons = true,
  showLinks = true,
  authButtons = () => null,
}) => {
  return (
    <div
      className={classnames('flex flex-row items-stretch justify-between h-100px z-110', className)}
    >
      <div
        className={classnames(
          'flex flex-row items-center space-x-12 py-10 lg:py-5 px-6 lg:px-10 flex-grow',
          navClassName,
        )}
      >
        <Link href="/">
          <Logo />
        </Link>
        {showLinks && (
          <div className="hidden lg:flex flex-row items-center">
            {links.map((link, index) => (
              <Link
                href={link.href}
                key={index}
                className="py-3 px-5 text-sm font-poppins hover:text-primary-150"
              >
                {link.label}
              </Link>
            ))}
            <div className="flex flex-row gap-3 px-4">
              {socialAccounts.map((element, index) => (
                <Link
                  href={element.href}
                  key={index}
                  className={`w-8 h-8 flex flex-row items-center justify-center rounded-4px
                    bg-${element.color || 'neutral-500'}
                    text-white`}
                >
                  {element.icon}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row items-center justify-center px-6 lg:hidden">
        {!menuShown && (
          <div className="cursor-pointer" onClick={onMenuShow}>
            <BaselinePages />
          </div>
        )}
        {menuShown && (
          <div className="cursor-pointer" onClick={onMenuHide}>
            <BaselineClose />
          </div>
        )}
      </div>
      {showAuthButtons && (
        <div className="flex-shrink-0 w-330px hidden lg:flex py-5 px-10 flex flex-row justify-end">
          <LandingHeaderAuthButtons />
        </div>
      )}
      {!showAuthButtons && authButtons()}
    </div>
  );
};

export default LandingHeader;
