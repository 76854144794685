import React from 'react';
import classnames from 'classnames';
import Image from 'next/image';

// @ts-ignore
const Logo: React.FC<any> = ({
  className,
  imageSrc = '/logo-only.svg',
  isSolo = false,
  inverse,
  name = 'Ambassadr',
  isBrand = false,
}) => {
  return isBrand ? (
    <div className="flex flex-col items-center">
      <img
        className="flex flex-shrink-0 max-h-[50px] max-w-[225px]"
        src={imageSrc}
        alt="logo only"
      />
      <span
        className={classnames(
          'hidden xl:flex flex flex-row items-center text-xs font-bebas select-none text-neutral-400',
          className,
          { hidden: isSolo },
        )}
      >
        by Ambassadr
      </span>
    </div>
  ) : (
    <div className="flex flex-row items-center space-x-4" style={{ height: 45 }}>
      <img className="flex flex-shrink-0 h-full" src={imageSrc} alt="logo only" />
      <span
        className={classnames(
          'hidden xl:flex flex flex-row items-center text-2xl font-bebas select-none text-black',
          className,
          { hidden: isSolo },
        )}
      >
        {name}
      </span>
    </div>
  );
};

export default Logo;
