import React from 'react';

import * as Sentry from '@sentry/nextjs';

import Layout from '../Layout';
import FormButton from '@components/Core/Forms/FormButton';

const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  return (
    <Layout title="Error" description="Error">
      <div className="flex-grow pt-100px flex flex-col items-center justify-center py-10 px-10 space-y-6">
        <div className="flex-grow pt-100px flex flex-col items-center justify-center py-10 px-10 space-y-6 max-w-696px">
          <div className="flex flex-col items-center space-y-6">
            <img
              className="w-180px h-180px object-contain object-center flex-shrink-0"
              src="/images/500.svg"
              alt="404"
            />
            <span className="text-5xl font-barlow-condensed font-bold text-center">
              Oooops! Something went wrong
            </span>
            <p className="text-center">
              If issues persist, please reach out to{' '}
              <a href="mailto:support@ambassadr.com">support@ambassadr.com</a> to get some
              assistance as fast as possible! You can also join our discord to get help.
            </p>
            <p className="text-center">
              The page you’re looking for can’t be found or you hit an error. Let’s try this again,
              shall we?
            </p>
            <FormButton
              onClick={() => {
                Sentry.captureException(error);
                resetErrorBoundary();
              }}
            >
              Try again
            </FormButton>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ErrorFallback;
