export enum Industry {
  BEAUTY = 0,
  CLOTHING = 1,
  ELECTRONICS = 2,
  FURNITURE = 3,
  JEWELRY = 4,
  PAINTING = 5,
  PHOTOGRAPHY = 6,
  RESTAURANT = 7,
  GROCERIES = 8,
  FOOD_AND_DRINKS = 9,
  SPORTS = 10,
  OTHER = 11,
}

export enum Revenue {
  STARTER = 0, // '$0 (I am getting started)',
  YEAR_0_5K = 1, //'Up to $5,000 USD a year',
  YEAR_5_50K = 2, //'$5,000 USD to $50,000 a year',
  YEAR_50_250K = 3, //'$50,000 USD to $250,000 a year',
  YEAR_250_1000K = 4, //'$250,000 USD to $1,000,000 a year',
  YEAR_1000K_PLUS = 5, //'$1,000,000+ a year',
}

export enum SellStatus {
  TESTING = 0, // I am just playing around
  NOT_SELLING_YET = 1, // I am not selling products yet
  SELLING_OFFLINE = 2, // I’m selling, just not online
  SELLING = 3, // I am selling
}

export enum EmployeeGroup {
  EMPLOYEE_0_5 = 0, // under 5
  EMPLOYEE_5_10 = 1, // 5-10
  EMPLOYEE_10_50 = 2, // 10-50
  EMPLOYEE_50_100 = 3, // 50-100
  EMPLOYEE_100_PLUS = 4, // 100+
}
