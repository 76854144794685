import React from 'react';
import CampaignGlobalMetaContext from '@context/campaignGlobalMetaContext';
import { useAPI } from '@hooks';
import useUser from '@hooks/auth/useUser';

type Props = {
  children?: React.ReactNode;
};

export const CampaignGlobalMetaProvider: React.FC<Props> = ({ children }) => {
  const { user } = useUser();
  if (!user) {
    return <>{children}</>;
  }

  const { data: meta, isLoading, error, mutate } = useAPI('/api/me/meta', {
    revalidateOnFocus: false,
  });

  return (
    <CampaignGlobalMetaContext.Provider
      value={{
        meta: meta || {},
        isLoading,
        error,
        mutate,
      }}
    >
      {children}
    </CampaignGlobalMetaContext.Provider>
  );
};
