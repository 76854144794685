import React from 'react';
import Link from 'next/link';
import { links } from '@components/Index/LandingHeader';
import classnames from 'classnames';
import LandingHeaderAuthButtons from '@components/Index/LandingHeader/LandingHeaderAuthButtons';
// @ts-ignore
import Div100vh from 'react-div-100vh';

type Props = {
  className?: string;
};

const MobileMenu = ({ className }: Props, ref: React.ForwardedRef<any>) => {
  return (
    <div className={classnames('fixed left-0 top-0 right-0 bottom-0 z-50', className)}>
      <div className="w-full h-full">
        <Div100vh className="w-full">
          <div ref={ref} className="w-full h-full overflow-y-auto">
            <div className="pt-100px">
              <div className="w-full py-16 px-6 min-h-full bg-white flex flex-col">
                <div className="flex flex-col">
                  {links.map((link, index) => (
                    <Link
                      href={link.href}
                      key={index}
                      className="px-14 py-6 text-2xl font-poppins font-semibold text-neutral-400"
                    >
                      {link.label}
                    </Link>
                  ))}
                </div>
                <div className="flex-grow my-8" />
                <div className="flex-shrink-0 self-stretch flex justify-center">
                  <div className="flex-grow">
                    <LandingHeaderAuthButtons dir="col" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Div100vh>
      </div>
    </div>
  );
};

export default React.forwardRef(MobileMenu);
