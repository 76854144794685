import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ButtonGroup = ({
  alignCenter,
  alignEven,
  alignRight,
  className,
  children,
  fullWidth,
  fullWidthItems,
  gap,
  style,
  vertical,
}) => {
  const renderableChildren = Array.isArray(children) ? children.filter(i => i) : [children];
  const childCount = renderableChildren.length - 1;
  return (
    <>
      <div
        style={style}
        className={classnames(
          'button-group',
          {
            full: fullWidth,
            vertical: vertical,
            alignRight: alignRight,
            alignCenter: alignCenter,
            alignEven: alignEven,
          },
          className,
        )}
      >
        {React.Children.map(renderableChildren, (child, index) => (
          <div
            key={index} // eslint-disable-line
            className={classnames('item', {
              noMargin: index === childCount,
              full: fullWidthItems,
            })}
            style={{
              marginRight: gap,
              ...(vertical && {
                marginRight: 0,
                marginBottom: gap,
              }),
            }}
          >
            {child}
          </div>
        ))}
      </div>
      <style jsx>
        {`
          .button-group {
            position: relative;
            display: inline-flex;
            width: auto;
            height: auto;
            align-items: center;
            justify-content: flex-start;
          }

          .button-group.vertical {
            flex-direction: column;
          }

          .button-group.full {
            width: 100%;
          }

          .button-group.alignRight {
            justify-content: flex-end;
          }

          .button-group.alignCenter {
            justify-content: center;
          }

          .button-group.alignEven {
            justify-content: space-around;
          }

          .button-group .item {
            width: auto;
            height: auto;
          }

          .button-group .item.full {
            width: 100%;
          }

          .button-group .item.noMargin {
            margin-right: 0 !important;
            margin-bottom: 0 !important;
          }
        `}
      </style>
    </>
  );
};

ButtonGroup.defaultProps = {
  alignCenter: false,
  alignEven: false,
  alignRight: false,
  className: '',
  fullWidth: false,
  fullWidthItems: false,
  gap: 15,
  style: null,
  vertical: false,
};

ButtonGroup.propTypes = {
  alignCenter: PropTypes.bool,
  alignEven: PropTypes.bool,
  alignRight: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  fullWidthItems: PropTypes.bool,
  gap: PropTypes.number,
  style: PropTypes.object,
  vertical: PropTypes.bool,
};

export default ButtonGroup;
