import React from 'react';
import classnames from 'classnames';

const HighlightWord = ({ children, type = 'info' }) => {
  return (
    <>
      <span className={classnames('highlight', `highlight--${type}`)}>
        <span className="block-text">
          {children}
          <div className="block-color" />
        </span>
      </span>
      <style jsx>
        {`
          .highlight {
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            letter-spacing: inherit;
            font-family: inherit;
          }

          .block-text {
            position: relative;
            width: auto;
            height: auto;
            padding: 0 14px;
            margin-left: 8px;
          }

          .block-color {
            position: absolute;
            top: 50%;
            left: 0;
            height: 40%;
            width: 100%;
            z-index: 1;
          }

          .highlight--info {
            color: rgb(104, 156, 235);
          }

          .highlight--info .block-color {
            background-color: rgba(104, 156, 235, 0.2);
          }

          .highlight--primary {
            color: rgb(241, 130, 152);
          }

          .highlight--primary .block-color {
            background-color: rgba(241, 130, 152, 0.2);
          }
        `}
      </style>
    </>
  );
};

export default HighlightWord;
