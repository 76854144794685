import { useContext } from 'react';

import { useTreatments, SplitContext } from '@splitsoftware/splitio-react';

const useFeatureToggle = (featureName = '') => {
  const { isReady } = useContext(SplitContext);
  const treatments = useTreatments([featureName]);

  const handlers = {};

  return [
    {
      isReady,
      treatment: treatments[featureName],
    },
    handlers,
  ];
};

export default useFeatureToggle;
