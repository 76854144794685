import React from 'react';
import { AppProps } from 'next/app';
import {
  AnimatePresence,
  DefaultSeo,
  ErrorFallback,
  ErrorBoundary,
  Waiter,
  Toaster,
} from '@components';
import { useRouterProgress } from '@hooks';
import useLayout from '@hooks/useLayout';
import '@styles/index.scss';
import { UserProvider } from '@hoc/UserProvider';
import { CampaignGlobalMetaProvider } from '@hoc/CampaignGlobalMetaProvider';
import dynamic from 'next/dynamic';

const SocialAccountsConnectAlert = dynamic<any>(
  () => import('@components/Core/SocialAccountsConnectAlert'),
  {
    ssr: false,
    loading: () => null,
  },
);

const LayoutRenderer = ({ Component, pageProps }: Omit<AppProps, 'router'>) => {
  const { getLayout } = useLayout(Component);
  return getLayout(<Component {...pageProps} />);
};

const App = ({ Component, pageProps, router }: AppProps) => {
  useRouterProgress();
  return (
    <React.StrictMode>
      <ErrorBoundary onReset={() => {}} FallbackComponent={ErrorFallback}>
        <DefaultSeo
          defaultTitle="Ambassadr"
          titleTemplate="%s | Ambassadr"
          description=""
          openGraph={{
            type: 'website',
            locale: 'en_EN',
            url: `https://ambassadr.com${router.route}`,
            site_name: 'Ambassadr',
            title: 'Ambassadr',
            description: '',
            images: [{ url: '', width: 120, height: 120 }],
          }}
          twitter={{
            handle: '@ambassadr',
            cardType: 'summary_large_image',
          }}
        />
        <Waiter>
          <Toaster />
          <UserProvider>
            <CampaignGlobalMetaProvider>
              <AnimatePresence
                exitBeforeEnter
                initial={false}
                onExitComplete={() => window.scrollTo(0, 0)}
              >
                <LayoutRenderer Component={Component} pageProps={pageProps} />
              </AnimatePresence>
              <SocialAccountsConnectAlert />
            </CampaignGlobalMetaProvider>
          </UserProvider>
        </Waiter>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

export default App;
