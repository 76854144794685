import { createContext } from 'react';

export type CampaignMetaContext = {
  meta: {
    budget: number;
    budgetAvg: number;
    followersTotal: number;
    followersMin: number;
    infCount: number;
    itsRangeGroups: number[];
    itsRange: number[];
    itsAvg: number;
    itsMin: number;
    ageGroupMin: number | null;
    ageGroupMax: number;
  };
  influencers: any[];
  isLoading: boolean;
  isLoadingMeta: boolean;
  isLoadingInfluencers: boolean;
  mutateMeta: Function;
  mutateInfluencers: Function;
};

const noop = () => {};

const CampaignMetaContext = createContext<CampaignMetaContext>({
  isLoading: true,
  isLoadingMeta: true,
  isLoadingInfluencers: true,
  meta: {
    budget: 0,
    budgetAvg: 0,
    followersTotal: 0,
    followersMin: 0,
    infCount: 0,
    itsRangeGroups: [17, 34, 51, 68, 85, 100],
    itsRange: [0, 0, 0, 0, 0, 0],
    itsAvg: 0,
    itsMin: 0,
    ageGroupMin: 0,
    ageGroupMax: 0,
  },
  influencers: [],
  mutateMeta: noop,
  mutateInfluencers: noop,
});

export default CampaignMetaContext;
