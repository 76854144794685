import { useContext } from 'react';
import userContext from '../../context/userContext';

const useUser = () => {
  const {
    user,
    userId,
    nickname,
    isLoading,
    isAuthenticated,
    refresh,
    isInfluencer,
    isBrandManager,
    isNewRequests,
  } = useContext(userContext);
  return {
    user,
    userId,
    nickname,
    isLoading,
    isAuthenticated,
    refresh,
    isInfluencer,
    isBrandManager,
    isNewRequests,
  };
};

export default useUser;
