import React, { ReactNode } from 'react';

import classnames from 'classnames';
import { NextSeo } from 'next-seo';

import { useLandingMenu } from '@layouts/hooks/useLandingMenu';
import LandingHeader from '@components/Index/LandingHeader';
import MobileMenu from '@components/Index/MobileMenu';

type Props = {
  children: ReactNode;
  title?: string;
  description?: string;
  user?: any;
};

const Layout = ({ title, description, children, user }: Props) => {
  const { ref: menuRef, shown: menuShown, setShown: setMenuShown } = useLandingMenu();
  return (
    <>
      <NextSeo title={title} description={description} />
      <div className="flex flex-col w-screen min-h-screen">
        <LandingHeader
          className="fixed top-0 bg-white z-30 w-full overflow-hidden"
          menuShown={menuShown}
          onMenuShow={() => setMenuShown(true)}
          onMenuHide={() => setMenuShown(false)}
        />
        <MobileMenu
          ref={menuRef}
          className={classnames({
            'opacity-0 pointer-events-none': !menuShown,
          })}
        />
        {children}
      </div>
    </>
  );
};

export default Layout;
