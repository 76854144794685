import { useEffect } from 'react';

import { NProgress, useRouter } from '@components';

const useRouterProgress = () => {
  const router = useRouter();

  useEffect(() => {
    NProgress.configure({ showSpinner: false });
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', () => NProgress.start());

    router.events.on('routeChangeComplete', path => {
      // TODO: Add any integrations with GA
      NProgress.done();
    });

    router.events.on('routeChangeError', () => NProgress.done());
  }, [router]);

  return [];
};

export default useRouterProgress;
