import React, { forwardRef } from 'react';
import classnames from 'classnames';

const Button = forwardRef(({ className, buttonType = 'primary', ...rest }, ref) => {
  return (
    <>
      <button
        ref={ref}
        className={classnames('button', `button--${buttonType}`, {
          [className]: !!className,
        })}
        {...rest}
      />
      <style jsx>
        {`
          .button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            border: none;
            border-radius: 14px;
            opacity: 1;
            font-family: inherit;
            font-weight: 500;
            font-size: 16px;
            padding: 5px 20px;
            cursor: pointer;
            width: 80%;
          }

          .button--primary {
            text-align: center;
            color: white;
            letter-spacing: 0.54px;
            color: #ffffff;
            opacity: 1;
            background: transparent
              radial-gradient(
                closest-side at -2% 80%,
                rgba(239, 150, 204, 1) 0%,
                rgba(241, 130, 152, 1) 100%
              )
              0% 0% no-repeat padding-box;
            box-shadow: 0px 12px 31px rgba(241, 130, 152, 0.31);
          }

          .button--info {
            background-color: rgba(104, 156, 235, 0.1);
            color: #689ceb;
          }
        `}
      </style>
    </>
  );
});

export default Button;
