import React from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
};

const LoadingSpinner: React.FC<Props> = ({ className }) => {
  return (
    <>
      <div className={classNames('loading relative w-4 h-4', className)}>
        <div className="relative top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 border-solid border-current border-2 w-full h-full" />
      </div>
      <style jsx>{`
        @keyframes loading {
          0% {
            transform: translate(-50%, -50%) rotate(0deg);
          }
          100% {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
        .loading {
          transform: translateZ(0) scale(1);
          backface-visibility: hidden;
          transform-origin: 0 0;
        }
        .loading div {
          border-top-color: transparent;
          border-radius: 50%;
          animation: loading 1s linear infinite;
        }
      `}</style>
    </>
  );
};

export default LoadingSpinner;
