import useAPI from '@hooks/useAPI';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import useCampaignMeta from '@hooks/campaign/useCampaignMeta';

type Props =
  | {
      id?: number | string;
      [key: string]: any;
    }
  | undefined;
type ReturnProps = {
  data: any[] | undefined;
  isAddedToIFA: boolean;
  onAddToIFA: () => Promise<any>;
  onDeleteFromIFA: () => Promise<any>;
  isAddedToBase: boolean;
  onAddToBase: () => void;
  onDeleteFromBase: () => void;
  mutate: () => Promise<any>;
  isWaiting: boolean;
};
//TODO Refactor when influencer new flow will be clear
const useCampaignInfluencer = ({ id, orderId, ...props }: Props = {}): ReturnProps => {
  const { data, error, isLoading, mutate } = useAPI(`/api/campaign/${orderId}/inf`, {
    revalidateOnFocus: false,
  });
  const { mutateMeta, mutateInfluencers } = useCampaignMeta();

  const [busy, setBusy] = useState<boolean>(false);
  const payload = useMemo(() => {
    if (Array.isArray(data)) return data;
    return [];
  }, [data]);

  const isAddedToIFA = useMemo<boolean>(() => {
    return id && payload.find((i: any) => +i.id === +id);
  }, [id, payload]);

  const onAddToIFA = useCallback(async () => {
    if (!id) return;
    setBusy(true);
    const response = await fetch(`/api/campaign/${orderId}/inf/${id}`, {
      method: 'POST',
    });
    if (response.ok) {
      if (!payload) {
        await mutate();
      } else {
        if (!payload.find((i: any) => i.id === id)) {
          payload.push({ id, ...props });
          await mutate(payload, true);
        }
      }
    } else {
      toast.error('Can not be added to campaign');
    }
    mutateMeta();
    mutateInfluencers();
    setBusy(false);
  }, [id, payload]);
  const onDeleteFromIFA = useCallback(async () => {
    if (!id) return;
    setBusy(true);
    const response = await fetch(`/api/campaign/${orderId}/inf/${id}`, {
      method: 'DELETE',
    });
    if (response.ok) {
      if (payload) {
        await mutate(
          payload.filter((i: any) => i.id !== id),
          true,
        );
      }
    } else {
      toast.error('Issue on delete from campaign');
    }
    mutateMeta();
    mutateInfluencers();
    setBusy(false);
  }, [id, payload]);

  const noop = () => {};
  const isAddedToBase = false;
  const onAddToBase = noop;
  const onDeleteFromBase = noop;

  return {
    data: payload,
    isAddedToIFA,
    onAddToIFA,
    onDeleteFromIFA,
    isAddedToBase,
    onAddToBase,
    onDeleteFromBase,
    isWaiting: busy || isLoading,
    mutate,
  };
};

export default useCampaignInfluencer;
