import { createContext } from 'react';

export type UserSocialAccount = {
  network: string;
  username: string;
  meta: any;
};

export type User = {
  id: string;
  authId: string;
  email: string;
  firstName: string;
  lastName: string;
  age: number;
  gender: number;
  roles: string[];
  address: string | null;
  phone: string | null;
  bio: string | null;
  role: string;
  balance: number;
  optInMarketing: boolean;
  birthDate: string | null;
  avatarUrl: string | null;
  avatarKey: string | null;
  refKey: string | null;
  onboardingDone: boolean;
  socialAccounts: UserSocialAccount[];
  brandInfo: {
    name: string;
    industry: number;
    revenue: number;
    sellStatus: number;
    employeesGroup: number;
  };
  updatedAt: string;
  meta?: any;
} | null;

export type UserContext = {
  user: User;
  userId: string | null;
  nickname: string | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  refresh: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
  isInfluencer: boolean;
  isBrandManager: boolean;
  isNewRequests: boolean;
};

const UserContext = createContext<UserContext>({
  user: null,
  userId: null,
  nickname: null,
  isLoading: true,
  isAuthenticated: false,
  refresh: () => Promise.resolve(null),
  isInfluencer: false,
  isBrandManager: false,
  isNewRequests: false,
});

export default UserContext;
