/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

function useSticky() {
  const [isSticky, setSticky] = useState(false);
  const element = useRef(null);

  const debounced = useDebouncedCallback(
    // function
    () => {
      if (element && element.current) {
        window.scrollY > element.current.getBoundingClientRect().bottom
          ? setSticky(true)
          : setSticky(false);
      }
    },
    // delay in ms
    50,
  );

  useEffect(() => {
    window.addEventListener('scroll', debounced);
    return () => {
      window.removeEventListener('scroll', () => debounced);
    };
  }, [debounced]);

  return { isSticky, element };
}

export default useSticky;
