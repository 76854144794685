import React from 'react';
import Link from 'next/link';
import FormButton from '@components/Core/Forms/FormButton';
import useUser from '@hooks/auth/useUser';
import classnames from 'classnames';

type Props = {
  dir?: 'row' | 'col';
};

const LandingHeaderAuthButtons: React.FC<Props> = ({ dir = 'row' }) => {
  const { isAuthenticated, user } = useUser();
  return (
    <div
      className={classnames('flex-grow flex', {
        'flex-row items-center justify-end space-x-4': dir === 'row',
        'flex-col items-stretch space-y-4': dir === 'col',
      })}
    >
      {isAuthenticated ? (
        <a
          className={classnames('flex-grow flex-shrink-0  min-w-max', {
            'max-w-1/2': dir === 'row',
          })}
          href="/api/auth/logout"
        >
          <FormButton className="w-full" palette="neutral-150">
            <span className="whitespace-nowrap">Logout</span>
          </FormButton>
        </a>
      ) : (
        <>
          <a
            className={classnames('flex-grow flex-shrink-0  min-w-max', {
              'max-w-1/2': dir === 'row',
            })}
            href="/api/auth/login"
          >
            <FormButton className="w-full" palette="primary-150">
              <span className="whitespace-nowrap">Sign In</span>
            </FormButton>
          </a>
          <a
            className={classnames('flex-grow flex-shrink-0  min-w-max', {
              'max-w-1/2': dir === 'row',
            })}
            href="/api/auth/login?initialscreen=signup"
          >
            <FormButton className="w-full" palette="neutral-150">
              <span className="whitespace-nowrap">Sign up</span>
            </FormButton>
          </a>
        </>
      )}
    </div>
  );
};

export default LandingHeaderAuthButtons;
