import React, { MouseEventHandler, useMemo } from 'react';
import classnames from 'classnames';
import LoadingSpinner from '@components/Core/LoadingSpinner';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  look?: 'default';
  size?: FormButtonSize;
  font?: FormButtonFontSize;
  palette?:
    | 'like'
    | 'instagram'
    | 'black'
    | 'white'
    | 'primary-150'
    | 'neutral-50'
    | 'neutral-150'
    | 'error'
    | 'pink-100'
    | 'success';
  disabled?: boolean;
  onClick?: MouseEventHandler;
  busy?: boolean;
}

type FormButtonSchemas = {
  [key: string]: {
    blur: FormButtonSchemaPalette;
    hover?: FormButtonSchemaPalette;
  };
};

type FormButtonSchemaPalette = {
  bg?: string;
  border?: string;
  text?: string;
  shadow?: string;
  borderRadius?: string;
  [key: string]: any;
};

type FormButtonSize = 'xs' | 'sm' | 'normal' | 'lg';
type FormButtonFontSize = 'xs' | 'sm';

const FormButtonSchemas: FormButtonSchemas = {
  like: {
    blur: {
      bg: 'pink-100',
      border: 'none',
      text: 'white',
      borderRadius: '4px',
    },
  },
  instagram: {
    blur: {
      bg: 'gradient-instagram',
      border: 'none',
      text: 'white',
      borderRadius: '4px',
    },
  },
  black: {
    blur: {
      bg: 'black',
      border: 'black',
      text: 'white',
      borderRadius: '4px',
    },
  },
  white: {
    blur: {
      bg: 'white',
      border: 'white',
      text: 'black',
      borderRadius: '4px',
    },
  },
  'primary-150': {
    blur: {
      bg: 'primary-150',
      border: 'transparent',
      text: 'white',
      borderRadius: '4px',
    },
    hover: {
      bg: 'primary-250',
      border: 'transparent',
      text: 'white',
      borderRadius: '4px',
    },
  },
  'neutral-50': {
    blur: {
      bg: 'neutral-50',
      border: 'transparent',
      text: 'neutral-400',
      borderRadius: '4px',
    },
  },
  'neutral-150': {
    blur: {
      bg: 'neutral-150',
      border: 'transparent',
      text: 'neutral-400',
      borderRadius: '4px',
    },
  },
  error: {
    blur: {
      bg: 'red-200',
      border: 'red-200',
      text: 'red-200',
      borderRadius: '4px',
    },
    hover: {
      bg: 'red-400',
      border: 'red-400',
      text: 'red-200',
      borderRadius: '4px',
    },
  },
  success: {
    blur: {
      bg: 'success-100',
      border: 'transparent',
      text: 'white',
      borderRadius: '4px',
    },
  },
};

const FormButton = (
  {
    children,
    look = 'default',
    size = 'normal',
    font = 'sm',
    palette = 'primary-150',
    type = 'button',
    disabled = false,
    className,
    onClick = () => {},
    busy,
    ...restOfProps
  }: Props,
  ref: React.ForwardedRef<any>,
) => {
  const classes: string = useMemo(() => {
    const data = [
      FormButtonSchemas[palette]?.blur?.bg && `bg-${FormButtonSchemas[palette]?.blur?.bg}`,
      FormButtonSchemas[palette]?.blur?.border &&
        `border-${FormButtonSchemas[palette]?.blur?.border}`,
      FormButtonSchemas[palette]?.blur?.text && `text-${FormButtonSchemas[palette]?.blur?.text}`,
      FormButtonSchemas[palette]?.blur?.shadow &&
        `shadow-${FormButtonSchemas[palette]?.blur?.shadow}`,
      FormButtonSchemas[palette]?.blur?.borderRadius &&
        `rounded-${FormButtonSchemas[palette]?.blur?.borderRadius}`,
      FormButtonSchemas[palette]?.hover?.bg && `hover:bg-${FormButtonSchemas[palette]?.hover?.bg}`,
      FormButtonSchemas[palette]?.hover?.border &&
        `hover:border-${FormButtonSchemas[palette]?.hover?.border}`,
      FormButtonSchemas[palette]?.hover?.text &&
        `hover:text-${FormButtonSchemas[palette]?.hover?.text}`,
      FormButtonSchemas[palette]?.hover?.shadow &&
        `hover:shadow-${FormButtonSchemas[palette]?.hover?.shadow}`,
    ];
    if (disabled) {
      data.push(`opacity-50 pointer-events-none`);
    }
    return data.filter(Boolean).join(' ');
  }, [look, size, font, palette, disabled]);
  return (
    <button
      className={classnames(
        'flex flex-col items-center justify-center focus:outline-none border font-medium whitespace-nowrap',
        className,
        classes,
        {
          'p-1.5': size === 'xs',
          'py-2 px-4': size === 'sm',
          'py-3 px-5': size === 'normal',
          'py-4 px-12': size === 'lg',
          'text-xs': font === 'xs',
          'text-sm': font === 'sm',
        },
      )}
      type={type}
      onClick={e => !disabled && onClick(e)}
      ref={ref}
      {...restOfProps}
    >
      <div className="flex flex-col self-stretch items-stretch">
        <div
          className={classnames('flex flex-row items-center justify-center flex-grow', {
            'h-0 opacity-0': busy,
          })}
        >
          {children}
        </div>
        <div
          className={classnames('flex flex-row items-center justify-center flex-grow', {
            'h-0 opacity-0': !busy,
          })}
        >
          <LoadingSpinner />
        </div>
      </div>
    </button>
  );
};

export default React.forwardRef(FormButton);
