import React, { useEffect, useMemo } from 'react';
import { useAPI } from '@hooks';
import UserContext from '@context/userContext';
import { useOnboarding } from '@hooks/auth/useOnboarding';
import useUser from '@hooks/auth/useUser';

type Props = {
  children?: React.ReactNode;
};

const OnboardingAuth: React.FC<Props> = ({ children }) => {
  const context = useUser();
  useOnboarding(context);
  return <>{children}</>;
};

export const UserProvider: React.FC<Props> = ({ children }) => {
  const { data, isLoading, mutate, error } = useAPI('/api/me', {
    revalidateOnFocus: false,
  });

  const user = useMemo(() => {
    if (data && !data.error) {
      return data;
    }
    return null;
  }, [data]);

  const userId = React.useMemo(() => user?.id?.toString() || '', [user]);

  const nickname = React.useMemo(() => {
    return [user?.firstName, user?.lastName].join(' ');
  }, [user]);

  const isBrandManager = useMemo(() => {
    return user && user.role === 'brand-manager';
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        userId,
        nickname,
        isLoading,
        isAuthenticated: !!user,
        refresh: mutate,
        isBrandManager,
        isInfluencer: !isBrandManager && user && user.role === 'user',
        isNewRequests: user?.isNewRequests || false,
      }}
    >
      {!isLoading && <OnboardingAuth>{children}</OnboardingAuth>}
    </UserContext.Provider>
  );
};
