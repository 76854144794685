import React from 'react';

import classnames from 'classnames';
import { motion } from 'framer-motion';
import Link from 'next/link';

import styles from './AnimatedButton.module.scss';

type Props = {
  children?: any;
  href?: string;
  variant?: string;
  color?: string;
  className?: string;
  animated?: boolean;
  uppercase?: boolean;
  weighted?: boolean;
  small?: boolean;
  [key: string]: any;
};

const AnimatedButton = ({
  children,
  variant = 'filled',
  color = 'white',
  className = '',
  animated = true,
  uppercase = true,
  weighted = true,
  small = true,
  ...props
}: Props) => {
  const MotionComponent = props.href ? motion.a : motion.button;

  const main = (
    <MotionComponent
      className={classnames(
        styles.button,
        styles[variant],
        styles[color],
        {
          [styles.uppercase]: uppercase,
          [styles.weighted]: weighted,
          [styles.small]: small,
        },
        className,
      )}
      {...props}
      {...(animated
        ? {
            whileTap: { scale: 0.97 },
          }
        : {})}
    >
      {children}
    </MotionComponent>
  );

  const linkWrap = props.href && props.href[0] === '/';

  return linkWrap ? <Link href={props?.href || '/'}>{main}</Link> : main;
};

export default AnimatedButton;
