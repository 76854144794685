import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

type Props = {};
export const useLandingMenu = () => {
  const { events: routerEvents } = useRouter();
  const [menuShown, setMenuShown] = useState<boolean>(false);
  const menuRef = useRef(null);
  useEffect(() => {
    routerEvents.on('routeChangeStart', () => {
      if (menuRef) {
        const current = menuRef.current;
        if (current) {
          enableBodyScroll(current);
        }
      }
    });
    routerEvents.on('routeChangeComplete', () => {
      setMenuShown(false);
    });
  }, []);
  useEffect(() => {
    if (menuRef) {
      const current = menuRef.current;
      if (current) {
        if (menuShown) {
          disableBodyScroll(current);
        } else {
          enableBodyScroll(current);
        }
      }
    }
  }, [menuShown]);
  return {
    ref: menuRef,
    shown: menuShown,
    setShown: setMenuShown,
  };
};
