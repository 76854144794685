import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import isEqual from 'lodash.isequal';
import { UserContext } from '@context/userContext';
import { UserRole } from '@lib/db/models/dictionaryUser';

export const useOnboarding = (context: UserContext) => {
  const { user, isLoading, isAuthenticated } = context;

  const { events, push, route } = useRouter();

  const shouldOnboardingRef = useRef(false);

  useEffect(() => {
    shouldOnboardingRef.current =
      !user ||
      !user.onboardingDone ||
      (user.role === UserRole.BRAND && (!user.brandInfo || !user.brandInfo.name));
  }, [user, user?.onboardingDone]);

  const onRouteChangeStart = useCallback(
    e => {
      if (isAuthenticated) {
        switch (e) {
          case '/event':
          case '/auth/signup':
            break;
          default:
            if (shouldOnboardingRef.current) {
              events.emit('routeChangeError', route);
              throw 'Unauthenticated';
            }
        }
      }
    },
    [isAuthenticated, user],
  );

  const onRouteChangeError = useCallback(
    e => {
      if (isAuthenticated) {
        switch (e) {
          case '/auth/signup':
            break;
          default:
            if (shouldOnboardingRef.current) {
              push('/auth/signup').then();
            }
        }
      }
    },
    [isAuthenticated, user],
  );

  useEffect(() => {
    events.on('routeChangeError', onRouteChangeError);
    events.on('routeChangeStart', onRouteChangeStart);
    try {
      onRouteChangeStart(route);
    } catch (e) {
      console.warn(e);
    }
    return () => {
      events.off('routeChangeStart', onRouteChangeStart);
    };
  }, []);
};
